import ProductN11 from "../assets/headphone_1_1.png";
import ProductN12 from "../assets/headphone_1_2.png";
import ProductN13 from "../assets/headphone_1_3.png";

import ProductN21 from "../assets/headphone_2_1.png";
import ProductN22 from "../assets/headphone_2_2.png";
import ProductN23 from "../assets/headphone_2_3.png";

import ProductN31 from "../assets/headphone_3_1.png";
import ProductN32 from "../assets/headphone_3_2.png";
import ProductN33 from "../assets/headphone_3_3.png";

export const data = [
  {
    img1: ProductN11,
    img2: ProductN12,
    img3: ProductN13,
    title: "sennhesiser HD 450BT Headphones",
    price: 199.9,
    id: 1,
    details:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. adipisicing elit. Iure provident Obcaecati pariatur facere quos quia molestiae. nihil aut animi eos Eveniet fuga et tenetur non incidunt.",
  },
  {
    img1: ProductN21,
    img2: ProductN22,
    img3: ProductN23,
    title: "headphone Appel power microphone ",
    price: 109.9,
    id: 2,
    details:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Iure provident adipisicing elit. Iure provident a nihil aut animi eos temporibus dolorem quae nihil aut animi eos deleniti. Nihil.",
  },
  {
    img1: ProductN31,
    img2: ProductN32,
    img3: ProductN33,
    title: "headphone GLX 3 with speacker",
    price: 149.9,
    id: 3,
    details:
      "Lorem ipsum dolor sit psum dolor sit adipisicing elit. Iure provident amet consectetur, adipisicing elit. nihil aut animi eos Inventore, nihil aut animi eos impedit. Soluta e psum dolor sit adipisicing elit. Iure provident amet consectetur, adipisicing elit. nihil aut animi eos Inventore, nihil aut animi eos impedit. Soluta e adipisicing elit. Iure provident amet consectetur, adipisicing elit. nihil aut animi eos Inventore, nihil aut animi eos impedit. Soluta e dolore, corrupti adipisci a?",
  },
];
